<template>
    <div>
        <div class="all-height d-flex justify-center">
            <div class="all-width">
                <v-card loader-height="2">
                    <v-card-text v-if="isPlanExpaired">
                        <br>
                        <br>
                        <br>
                        <div class="text-h5 text-center font-weight-bold" align-center>
                            {{ expiryMessage }}
                        </div>
                        <br>
                        <br>
                        <div class="text-h6 text-center font-weight-bold text-decoration-underline">
                            <v-btn @click="activatePlan()" :loading=loading v-if="reactivatebtnshow">
                                {{ reactivate }}
                            </v-btn>
                        </div>
                    </v-card-text>
                    <div v-else>
                        <v-card-text class="pb-0" v-if="isFirst">
                            <br>
                            <br>
                            <br>
                            <div class="text-h5 text-center font-weight-bold" align-center>
                                Welcome!!! We are happy to help you set up a clutter free close cycle
                            </div>
                            <br>
                            <br>
                            <div class="text-h6 text-center font-weight-bold text-decoration-underline">
                                <v-btn @click="letsGetStart()">
                                    Let's get you started!
                                </v-btn>
                            </div>
                        </v-card-text>
                        <v-card-text v-else>
                            <br>
                            <div class="text-h5 text-center font-weight-bold" align-center>
                                Overview
                            </div>
                            <br>
                            <br>
                            <DataTable :headers="headers" :items="formdata" :loading="loading" :enableslot="['goto']">

                                <template v-slot:goto="{ item }">
                                    <div class="d-flex align-center justify-center">
                                        <v-tooltip bottom content-class="tooltip-bottom">
                                            <template v-slot:activator="{ attrs }">
                                                <v-btn small @click="$nova.gotoLink({ path: item.goto })" v-bind="attrs">
                                                    <v-icon> mdi mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                    </div>
                                </template>
                            </DataTable>
                        </v-card-text>
                    </div>
                </v-card>
                <lb-dialog v-model="startDialog" width="500">
                    <template v-slot:body>
                        <div class="">
                            <v-row>
                                <v-col>
                                    <div class="text-body-1">Do you want to set up your close tasklist masters?</div>
                                    <div class="font-weight-thin">Setting up masters significantly hastens the process to
                                        set up new close cycles</div>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                    <template v-slot:actions>
                        <v-spacer></v-spacer>
                        <div class="">
                            <v-row>
                                <v-col class="ma-2 pa-2">
                                    <v-btn small @click="$nova.gotoLink({ path: '/financialclose/projects' })">
                                        <span>Skip this for now</span>
                                    </v-btn>
                                    <v-btn small color="fbcPrimary "
                                        @click="$nova.gotoLink({ path: '/financialclose/manage/chartofaccount' })">
                                        <span>Get started</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </lb-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from "../../../views/common/DataTable.vue";
export default {
    name: 'financialclose_virtualconcierge_list',
    // props: ['props'],

    components: {
        DataTable,
    },
    data() {
        return ({
            startDialog: false,
            formdata: [],
            isFirst: false,
            loading: false,
            isPlanExpaired: false,
            expiryMessage: "",
            reactivate: "",
            reactivatebtnshow: true,
            headers: [
                {
                    value: "indexno",
                    text: "SL No",
                    sortable: false,
                    filterable: false,
                },
                {
                    value: "name",
                    text: "Name",
                    sortable: false,
                    filterable: false,
                },
                {
                    value: "length",
                    text: "Count",
                    sortable: false,
                    filterable: false,
                },
                {
                    value: "lastUpdated",
                    text: "Last Updated On",
                    sortable: false,
                    filterable: false,
                },
                {
                    value: "goto",
                    text: "",
                    sortable: false,
                    filterable: false,
                }
            ],
            timeFormat: {
                month: 'short',   // Short month name (e.g., Dec)
                day: 'numeric',   // Day of the month as a numeric value (e.g., 28)
                year: 'numeric',  // Full year (e.g., 2023)
                hour: 'numeric',  // Hour (e.g., 16)
                minute: 'numeric'
            }
        })
    },
    created() {
        this.$store.commit("setProjectName", "");
        this.init();
    },
    activated() {
        this.$store.commit("setProjectName", "");
        this.init();
    },
    methods: {
        init() {
            if (this.$store.state.subscriptions.financialclose.plan_detail.expiry) {
                this.isPlanExpaired = this.$store.state.subscriptions.financialclose.plan_detail.expiry || false;
                this.expiryMessage = "Your free trail is expired. Please Reactivate";
                this.reactivate = "Reactivate"
            }
            this.axios.post("/v2/financialclose/project/get")
                .then(async (dt) => {
                    if (dt.data.status === "success") {
                        if (dt.data.data.length <= 0) {
                            this.isFirst = true
                        }
                        else {
                            this.isFirst = false
                        }
                        if (dt.data.data.length > 0) {
                            const updatedDates = [];
                            for (const item in dt.data.data) {
                                updatedDates.push(new Date(dt.data.data[item].updated_at))
                            }
                            const latestUpdated = new Date(Math.max.apply(null, updatedDates)).toLocaleString('en-IN', this.timeFormat).replace(/\b(?:am|pm)\b/g, match => match.toUpperCase());
                            this.formdata.push({ "name": "Close cycle", "length": dt.data.data.length, "lastUpdated": latestUpdated, "goto": "/financialclose/projects" })
                        }


                    }
                    else {
                        this.formdata.push({ "name": "Close cycle", "length": 0 })
                    }
                }
                );
            this.axios.post("/v2/financialclose/masters/informationmanager/get")
                .then((dt) => {
                    if (dt.data.status === "success") {
                        if (dt.data.data.length <= 0) {
                            this.isFirst = true
                        }
                        else {
                            this.isFirst = false
                        }
                        if (dt.data.data.length > 0) {
                            const updatedDates = [];
                            for (const item in dt.data.data) {
                                if (dt.data.data[item].updated_at) {
                                    updatedDates.push(new Date(dt.data.data[item].updated_at))
                                }
                                else {
                                    updatedDates.push(new Date(dt.data.data[item].created_at))
                                }
                            }
                            const latestUpdated = new Date(Math.max.apply(null, updatedDates)).toLocaleString('en-IN', this.timeFormat).replace(/\b(?:am|pm)\b/g, match => match.toUpperCase());

                            this.formdata.push({ "name": "Information manager", "length": dt.data.data.length, "lastUpdated": latestUpdated, "goto": "/financialclose/manage/informationmanager" });
                        }

                    }
                    else {
                        this.formdata.push({ "name": "Information manager", "length": 0 })
                    }
                }
                );
            this.axios.post("/v2/financialclose/masters/chartofaccount/get")
                .then((dt) => {
                    if (dt.data.status === "success") {
                        if (dt.data.data.length <= 0) {
                            this.isFirst = true
                        }
                        else {
                            this.isFirst = false
                        }
                        if (dt.data.data.length > 0) {
                            const updatedDates = [];
                            for (const item in dt.data.data) {
                                if (dt.data.data[item].updated_at) {
                                    updatedDates.push(new Date(dt.data.data[item].updated_at))
                                }
                                else {
                                    updatedDates.push(new Date(dt.data.data[item].created_at))
                                }
                            }
                            const latestUpdated = new Date(Math.max.apply(null, updatedDates)).toLocaleString('en-IN', this.timeFormat).replace(/\b(?:am|pm)\b/g, match => match.toUpperCase());
                            this.formdata.push({ "name": "Chart of Accounts", "length": dt.data.data.length, "lastUpdated": latestUpdated, "goto": "/financialclose/manage/chartofaccount" })
                        }

                    }
                    else {
                        this.formdata.push({ "name": "Chart of Account", "length": 0 })
                    }
                }
                );
        },
        letsGetStart() {
            this.startDialog = true;
        },
        popUpStatus() {
            this.startDialog = false;
        },
        activatePlan() {
            this.reactivate = "Request sending..."
            this.axios.post("/v2/financialclose/analysis/trialperiodextendrequest")
                .then((dt) => {
                    if (dt.data.status === "success") {
                        this.expiryMessage = "Request sent successfully";
                        this.reactivate = "Success";
                        this.reactivatebtnshow = false;
                    }
                    else {
                        this.expiryMessage = "Request failed. Please try again";
                        this.reactivate = "Try again"
                    }
                });
        }
    },
    watch: {
    }
}
</script>
<style>

h1,
p {
    margin: 0;
    text-align: center;
    /* Remove default margins to avoid extra space */
}

h1 {
    font-size: 1em;
}
</style>